@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.intro_container {
  height: 100%;
  margin-bottom: 110px;
  height: calc(100vh - 160px);
}

.loginContainer {
  min-width: 1100px;
  background-color: #f8f8f8;
  height: 888px;
}

.loginInnerContainer {
    max-width: 258px;
    margin: 0 auto;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loginIcon {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 41px;
}

.warningMessage {
  font-size: 12px;
  color: red;
  margin-top: 10px;
  max-width: 230px;
  word-break: keep-all;
}

.joinButton {
  width: 280px;
  height: 50px;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px auto;
  color: #000000;
  font-size: 18px;
  border: 1px solid black;
  margin-bottom: 25px;
  cursor: pointer;
}

.loginLinkDiv {
  @include flex-direction(row);
  @include justify-content(space-between);
  width: 75%;
  height: auto;
  .loginLinkText {
    cursor: pointer;
    color: $main-color;
    font-size: 15px;
    text-decoration: none;
  }
  .betweentext {
    display: inline;
    border-left: 1px solid $black;
  }
}

.login_section {
  @include center_mid();
  overflow: hidden;
  margin-top: 50px;
  background: $white;
  height: 100%;
  min-width: 1100px;
  min-height: 700px;
  background-repeat: no-repeat;
  background-position: 50% 60%;
  .login_container {
    width: 1080px;
    height: 100%;
    .login_area {
      @include center_mid();
      @include flex_direction(row);
      @include justify_content(space-between);
      
      .login_title_area {
        @include flex_direction(column);
        @include flex();
        @include justify_content(row);
        
        .login_title {
          color: $white;
          font-size: 46px;
          font-weight: $font-weight-bold;
          display: inline-block;
          line-height: 76px;
        }
      }
    }
    .loginForm {
      @include flex_direction(column);
      .loginInputContainer {
        margin-bottom: 15px;
        .loginInput {
          width: 280px;
          height: 48px;
          border: 1px solid $white;
          border-radius: 5px;
          font-size: 18px;
          padding: 0 10px;
          color: $black;
        }
      }
      
      .loginButton {
        width: 280px;
        height: 50px;
        margin: 15px 0;
        border-radius: 5px;
        background-color: $black;
        color: $white;
        font-size: 18px;
        border: none;
        cursor: pointer;
      }      
    }
  }
}

.login_box {
  @include center_mid();
  @include flex_direction(column);
  background-color: rgba(255,255,255,.8);
  padding: 50px 40px;
  border: 1px solid rgba(255,255,255,0);
  border-radius: 10px;
}

.login_area, .section2_area, .section3_area, .section4_area,
.section5_area {
  @include center_mid();
  @include flex_direction(column);
  background-color: rgba(255,255,255,0);
  height: 100%;
}

.section2 {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  background: $gray-6;
  height: 760px;

  .section2_container {
    margin: 0 auto;
    width: 1080px;
    height: 100%;

    .section2_area {
      @include flex_direction(row);
      @include justify_content(space-between);
      .section2_text_area {
        padding: 10px;
        .section2_title_wrap {
          margin: 20px 0;
          .section2_title {
            color: $black;
            font-size: 50px;
            font-weight: $font-weight-bold;
            line-height: 70px;
          }
        }
        .section2_text_wrap {
          margin: 20px 0;
          .section2_text {
            color: $black;
            font-size: 20px;
            line-height: 33px;
          }
        }
      }
      .section2_card_area {
        padding: 10px;
        .section2_card {
          width: 530px;
          height: 100px;
          border-radius: 10px;
          box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.16);
          background-color: $white;
          margin: 20px 0;
          .section2_card_row {
            @include flex_direction(row);
            @include align_items(center);
            @include justify_content(space-between);
            width: 100%;
            height: 100%;
            padding: 0 30px;
            label {
              font-size: 20px;
              color: #707070;
              border-bottom: 3px solid rgba(255, 167, 35, 0.16);
              box-shadow: inset 0 -15px 0 rgba(255, 167, 35, 0.16);
            }
            span {
              color: $black;
              font-size: 40px;
              font-weight: $font-weight-bold;
            }
          }
        }

        .section2_data_month {
          @include center_mid();
          padding: 30px 0;
          font-size: 16px;
          color: #707070;
        }
      }
    }
  }
}

.section3, .section5 {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  background: $white;
  height: 945px;
  .section3_container, .section5_container {
    margin: 0 auto;
    width: 1080px;
    height: 100%;
    .section3_area, .section5_area {
      @include flex_direction(row);
      @include justify-content(space-between);
      .section3_images_wrap, .section5_images_wrap {
        width: 320px;
        height: 645px;
      }
      .section3_text_area, .section5_text_area {
        padding: 10px;
        .section3_title_wrap, .section5_title_wrap {
          margin: 20px 0;
          .section3_title, .section5_title {
            color: $black;
            font-size: 50px;
            font-weight: $font-weight-bold;
            line-height: 70px;
          }
        }
      }
    }
  }
}

.section4 {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  background: $gray-6;
  height: 945px;
  .section4_container {
    margin: 0 auto;
    width: 1080px;
    height: 100%;
    .section4_area {
      @include flex_direction(row);
      @include justify-content(space-between);
      .section4_images_wrap {
        width: 320px;
        height: 645px;
      }
      .section4_text_area {
        padding: 10px;
        .section4_title_wrap {
          margin: 20px 0;
          .section4_title {
            color: $black;
            font-size: 50px;
            font-weight: $font-weight-bold;
            line-height: 70px;
          }
        }
      }
    }
  }
}

.app_store_section {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  height: 430px;
  background-position: 50% 70%;
  .app_store_container {
    margin: 0 auto;
    width: 1080px;
    height: 100%;
    .app_store_area {
      @include align_items(center);
      @include flex_direction(row);
      @include justify-content(space-between);
      height: 100%;
      .app_store_text_area {
        padding: 10px;
        .app_store_title_wrap {
          margin: 20px 0;
          .app_store_title {
            color: $white;
            font-size: 52px;
            font-weight: $font-weight-bold;
            line-height: 1.35;
          }
        }
      }
      .app_store_download_wrap {
        .app_store_explain_wrap {
          .app_store_explain_text {
            color: $white;
            font-size: 28px;
            line-height: 1.5;
            text-align: center;
          }
        }
        .app_store_button_wrap {
          margin-top: 40px;
          .app_store_button {
            width: 195px;
            height: 55px;
            background-color: transparent;
            border: none;
            margin: 0px 5px;
          }
        }
      }
    }
  }
}

.footer_section {
  position: relative;
  overflow: hidden;
  min-width: 1100px;
  height: 380px;
  margin-bottom: 110px;
  .footer_section_container {
    margin: 0 auto;
    width: 1080px;
    height: 100%;
    .footer_section_area {
      padding-top: 41px;
      .footer_company_term_area {
        @include flex_direction(row);
        @include justify-content(space-between);
        padding-bottom: 12px;
        border-bottom: 1px solid #e6e6e6;
        .footer_company_term_container {
          @include flex_direction(row);
          padding-top: 10px;
          .footer_company_term {
            padding: 0 10px;
            height: 18px;
            line-height: 18px;
            font-size: 15px;
            font-weight: bold;
            color: #8a8a8a;
            border-right: 1.5px solid #8a8a8a;
            text-decoration: none;
            cursor: pointer;
          }
          :last-child {
            border: none;
          }
        }
        .footer_naver_link {
          @include flex_direction(row);
          img {
            margin-left: 8px;
            cursor: pointer;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .footer_company_info {
      padding: 10px;
      font-size: 15px;
      :first-child {
        font-weight: bold;
      }
      div {
        font-size: 15px;
        height: 22px;
        color: #8a8a8a;
        font-weight: 200;
        margin: 2px 0;
        line-height: 1.6;
      }
    }
  }
}

.register_user_intro_modal_content_wrap {
  @include flex_direction(row);
  @include align_items(center);
  padding: 0px 15px;
  height: auto;
  p {
    margin: 5px;
  }
  .box_wrap {
    @include flex_direction(column);
    @include justify_content(space-between);
    width: 555px;
    min-height: 290px;
    margin: 10px;
    border: 1px solid $old-gray-3;
    .content_box {
      padding: 0 20px;
      .mainSentence { 
        padding-top: 10px;
      }
      .subSentence {
        font-size: 14px;
        color: $old-gray-3;
      }
    }
    .button {
      text-align: center;
      color: white;
      height: 47px;
      line-height: 3;
      background-color: $black;
      cursor: pointer;
    }
  }
}

.footer_bar_container {
  @include flex();
  @include center_mid();
  background-color: $black;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  color: $white;
  
  .footer_content {
    @include flex();
    @include center_mid();
    @include justify_content(space-between);
    width: 1080px;
    height: 100%;
  
    .footer_text {
      font-size: 20px;
      color: $white;
    }
    
    .button_start {
      background-color: $sub-color;
      color: $white;
      width: 300px;
      height: 60px;
      border-radius: 5px;
      border: none;
      font-size: 18px;
    }
  }
}

.mobile_image {
  @include center_mid();
  width: 320px;
  height: 645px;
}

.intro_float_button_area {
  position: fixed;
  bottom: 130px;
  right: 20px;
}