//font
$font-family: "Nanum Barun Gothic", sans-serif;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// font size
$h1-font-size: 30px;
$h2-font-size: 24px;
$h3-font-size: 18px;
$h4-font-size: 16px;
$h5-font-size: 14px;
$h6-font-size: 12px;
$xlarge-font-size: 18px;
$large-font-size: 16px;
$normal-font-size: 14px;
$small-font-size: 12px;
$smaller-font-size: 10px;

// mobile font size
$mobile-h1-font-size: 30px;
$mobile-h2-font-size: 24px;
$mobile-h3-font-size: 18px;
$mobile-h4-font-size: 16px;
$mobile-h5-font-size: 14px;
$mobile-h6-font-size: 12px;
$mobile-xlarge-font-size: 18px;
$mobile-large-font-size: 16px;
$mobile-normal-font-size: 14px;
$mobile-small-font-size: 12px;
$mobile-xsmall-font-size: 10px;
$mobile-large-font-size: 16px;
$mobile-normal-font-size: 14px;

$use-super-pass-background: #d9f6ff;
$use-super-pass-text: #02a0ff;
$receive-super-pass-background: #dcd8ff;
$receive-super-pass-text: #7169fe;

/* new color style guide (20/09/01 updated) */
/* new names */
$main-color: #282828;
$sub-color: #ffa723;
$gray-1: #36353a;
$gray-2: #575757;
$gray-3: #c5c0bc;
$gray-4: #d7d7d7; //button disabled color
$gray-5: #efefef;
$gray-6: #f6f6f6;
$gray-7: #f6f7f8;
$white: #ffffff;
$black: #333333;
$red: #f84b4b;
$blue: #0b55f9;
$green: #207244;

@function black-a($params) {
  @return rgba(0, 0, 0, $params);
}

/* new color style guide (20/08/07 updated) */
/* prev names */
// $main: #282828;
// $sub: #ffa723;
// $grey1: #36353a;
// $gray-2: #575757;
// $gray-3: #c5c0bc;
// $gray-4: #d7d7d7;
// $gray-5: #efefef;
// $gray-6: #f6f6f6;
// $grey7: #f6f7f8;

/* old colors */
// $color-1: #fff9f2; // not used
// $black: #333333; // 스타일 가이드에 없으므로 그대로 옮김
// $white: #ffffff; // 기존과 동일
$old-gray-1: #f7f7f7;
$old-gray-2: #a8a8a8;
$old-gray-3: #8d8d8d;
$old-gray-4: #696969;
$old-gray-5: #e5e5e5;

// $point-color1: #ffa723; // changed to $sub-color
// $main-color: #282828; // changed to $main-color
$old-sub-color: #373d46;
$old-error: #fd3b31;
$old-success: #40bf5e;
$old-disable: #f0f0f0;

/**
  new color set
*/
$new-gray-0: #f8f9fa;
$new-gray-1: #f2f4f6;
$new-gray-2: #edeff1;
$new-gray-3: #e6eaed;
$new-gray-4: #d1d6dc;
$new-gray-5: #b0b8c1;
$new-gray-6: #788595;
$new-gray-7: #4e5968;
$new-gray-8: #333d4b;

$new-blue-1: #e8f3ff;
$new-blue-2: #b8d8ff;
$new-blue-3: #96baf0;
$new-blue-4: #3182f6;
$new-blue-5: #1b64db;

$new-red-1: #ffeeee;
$new-red-2: #ffc4c7;
$new-red-3: #ed9ca2;
$new-red-4: #e42939;
$new-red-5: #d22031;

$new-green-1: #e3f7e4;
$new-green-2: #c4e5c3;
$new-green-3: #88c789;
$new-green-4: #00a661;
$new-green-5: #00834c;

@function kakao($name) {
  @if $name == "yellow" {
    @return #ffea92;
  } @else if $name == "yellow-bold" {
    @return #ffdc4a;
  } @else if $name == "skyblue" {
    @return #97cafd;
  } @else if $name == "blue" {
    @return #6b9af1;
  } @else if $name == "blue-bold" {
    @return #007de8;
  } @else if $name == "red" {
    @return #ffbebe;
  } @else if $name == "red-bold" {
    @return #e05656;
  }
}
