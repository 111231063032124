@mixin flex() {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin center_mid() {
	justify-content: center;
	-webkit-align-items: center;
  align-items: center;
  display: flex;
  display: -webkit-flex;
}

@mixin align_items($value) {
	display: -webkit-flex;
  display: flex;
	-webkit-align-items: $value;
  align-items: $value;
}

@mixin justify_content($value) {
  display: flex;
  display: -webkit-flex;
  justify-content: $value;
}

@mixin flex_direction($value) {
  display: flex;
	display: -webkit-flex;
	-webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex_wrap($value) {
  -webkit-flex-wrap: $value;
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

/* 1줄 말줄임표, 따로 width 지정 주어야함 */ 
@mixin ellipse() { 
  display: inline-block; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

@mixin word_break($width: auto, $height: auto) {
	width: $width;
	height: $height;
	overflow: auto;
	-ms-word-break: break-all;
	word-break: break-all;
}


@mixin box_sizing() {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin drag_disable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}